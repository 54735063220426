'use client';

import { FC } from 'react';

import { FaSync } from 'react-icons/fa';

export interface Props {
  text: string;
  apiRequest: () => void;
}

const ScreenTitle: FC<Props> = ({ text, apiRequest }) => {
  return (
    <>
      <div className="flex items-center space-x-4 mb-4">
        <div className="promotion-manager-title flex-1 text-gray-700 text-lg">
          {text}
        </div>
        <button
          onClick={apiRequest}
          className="flex items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          <FaSync className="mr-2" /> Refresh
        </button>
      </div>
    </>
  );
};

export default ScreenTitle;
