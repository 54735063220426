import { useState } from 'react';

import Sigma from 'sigma';

import { omit } from 'lodash';

import { DirectedGraph } from 'graphology';
import { Attributes } from 'graphology-types';

import ExpandSideToggle from 'components/ExpandSideToggle';

import { DualPane } from 'components/common';

import SearchField from 'components/Graph/SearchField';
import ClustersPanel from 'components/Graph/ClustersPanel';
import TagsPanel from 'components/Graph/TagsPanel';

import GraphDataController from 'components/Graph/DataController';
import GraphEventsController from 'components/Graph/EventsController';
import GraphSettingsController from 'components/Graph/SettingsController';
import GraphTitle from 'components/Graph/Title';
import GraphControllers from 'components/Graph/Controllers';
import GraphSelectedNode, {
  DrawerOptions,
} from 'components/Graph/SelectedNode';

import useDataContainerSettings from 'hooks/graph/useDataContainerSettings';
import useFetchRequestData from 'hooks/graph/useFetchRequestData';

import { SigmaContainer } from '@react-sigma/core';

import './style/index.css';
import { useFeatureFlags } from 'providers/FeatureFlagProvider';

const Loader = () => {
  const { dataset, filtersState, setFiltersState } = useFetchRequestData({
    URI: '/api/3/graph',
  });

  const [sigma, setSigma] = useState<Sigma<
    Attributes,
    Attributes,
    Attributes
  > | null>(null);

  const [hoveredNode, setHoveredNode] = useState<string | null>(null);
  const settings = useDataContainerSettings();

  const [expandToggleButtonIsActive, setExpandToggleButtonIsActive] =
    useState<boolean>(false);

  const [selectedNodeIsVisible, setSelectedNodeIsVisible] =
    useState<boolean>(true);

  const [drawerContentIsOpen, setDrawerIsVisible] = useState<boolean>(false);
  const drawerOptions: DrawerOptions = {
    isOpen: drawerContentIsOpen,
    setVisiblity: setDrawerIsVisible,
  };

  if (!dataset) return null;

  return (
    <>
      <section className="graph-tab-container">
        <DualPane
          className={`graph-tab-dual-pane ${
            expandToggleButtonIsActive ? 'collapsed' : ''
          }`}
        >
          <DualPane.SidePane className="side-pane-placeholder">
            {sigma && !expandToggleButtonIsActive && (
              <>
                <SearchField sigma={sigma} filters={filtersState} />
                <ClustersPanel
                  sigma={sigma}
                  clusters={dataset.clusters}
                  filters={filtersState}
                  setClusters={(clusters) =>
                    setFiltersState((filters) => ({
                      ...filters,
                      clusters,
                    }))
                  }
                  toggleCluster={(cluster) => {
                    setFiltersState((filters) => ({
                      ...filters,
                      clusters: filters.clusters[cluster]
                        ? omit(filters.clusters, cluster)
                        : { ...filters.clusters, [cluster]: true },
                    }));
                  }}
                />
                <TagsPanel
                  sigma={sigma}
                  tags={dataset.tags}
                  filters={filtersState}
                  setTags={(tags) =>
                    setFiltersState((filters) => ({
                      ...filters,
                      tags,
                    }))
                  }
                  toggleTag={(tag) => {
                    setFiltersState((filters) => ({
                      ...filters,
                      tags: filters.tags[tag]
                        ? omit(filters.tags, tag)
                        : { ...filters.tags, [tag]: true },
                    }));
                  }}
                />
              </>
            )}
          </DualPane.SidePane>
          <DualPane.ContentPane>
            <div className="content-pane">
              <div className="sigma-container">
                <SigmaContainer
                  graph={DirectedGraph}
                  className="react-sigma"
                  settings={settings}
                  ref={setSigma}
                >
                  <GraphSettingsController hoveredNode={hoveredNode} />
                  <GraphDataController
                    dataset={dataset}
                    filters={filtersState}
                  />
                  <GraphEventsController
                    hoveredNode={hoveredNode}
                    setHoveredNode={setHoveredNode}
                    setSelectedNodeIsVisible={setSelectedNodeIsVisible}
                  />
                  <GraphTitle filters={filtersState} />
                  <GraphControllers />
                  <GraphSelectedNode
                    hoveredNode={hoveredNode}
                    dataset={dataset}
                    selectedNodeIsVisible={selectedNodeIsVisible}
                    setSelectedNodeIsVisible={setSelectedNodeIsVisible}
                    drawerOptions={drawerOptions}
                  />
                </SigmaContainer>
              </div>
            </div>
          </DualPane.ContentPane>
          <ExpandSideToggle
            expandToggleButtonIsActive={expandToggleButtonIsActive}
            setExpandToggleButtonIsActive={setExpandToggleButtonIsActive}
          />
        </DualPane>
      </section>
    </>
  );
};

const GraphTab = () => {
  const { featureFlags } = useFeatureFlags();

  if (!featureFlags.dataset_graph) return null;

  return <Loader />;
};

export default GraphTab;
