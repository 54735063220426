import { useEffect, useState } from 'react';

import { constant, keyBy, mapValues } from 'lodash';

import { Dataset, FiltersState } from 'types/graph';

type Props = {
  URI: string;
};

function useFetchRequestData({ URI }: Props) {
  const [dataReady, setDataReady] = useState(false);
  const [dataset, setDataset] = useState<Dataset | null>(null);
  const [filtersState, setFiltersState] = useState<FiltersState>({
    clusters: {},
    tags: {},
  });

  useEffect(() => {
    fetch(URI)
      .then((res) => res.json())
      .then((dataset: Dataset) => {
        setDataset(dataset);
        setFiltersState({
          clusters: mapValues(keyBy(dataset.clusters, 'key'), constant(true)),
          tags: mapValues(keyBy(dataset.tags, 'key'), constant(true)),
        });
        requestAnimationFrame(() => setDataReady(true));
      });
  }, [URI]);

  return {
    dataset,
    setDataset,
    filtersState,
    setFiltersState,
    dataReady,
    setDataReady,
  };
}

export default useFetchRequestData;
