import { createReducer } from 'redux-act';
import { fetchPromotions, deletePromotion, approvalPromotion } from 'actions';
import {
  objectLoadStart,
  objectLoadError,
  objectLoadComplete,
} from 'reducers/util';
import _ from 'lodash';

const initialState = {};

export type PromotionsState = {
  [key: string]: {
    total: number;
    results: Array<any>;
  };
};

const filterOutItem = (
  data: PromotionsState,
  keyToExclude: string,
  idToExclude: string
) => {
  const clonedData = _.cloneDeep(data);

  for (const key in clonedData) {
    if (key !== keyToExclude) {
      continue;
    }

    clonedData[key].total -= 1;
    clonedData[key].results = clonedData[key].results.filter(
      (item: { id: string }) => item.id !== idToExclude
    );
  }

  return clonedData;
};

const updateItemInData = (
  data: PromotionsState,
  keyToUpdate: string,
  idToUpdate: string,
  updatedItem: string
) => {
  const clonedData = _.cloneDeep(data);

  for (const key in clonedData) {
    if (key !== keyToUpdate) {
      continue;
    }

    clonedData[key].results = clonedData[key].results.map(
      (item: { id: string }) => (item.id === idToUpdate ? updatedItem : item)
    );
  }

  return clonedData;
};

export default createReducer(
  {
    [fetchPromotions.START]: (state, { id }) => objectLoadStart(state, id),
    [fetchPromotions.ERROR]: (state, { error, args: { id } }) =>
      objectLoadError(state, id, error),

    [fetchPromotions.COMPLETE]: (state, { id, data }) =>
      objectLoadComplete(state, id, data),

    [deletePromotion.COMPLETE]: (state, { id, collectionId }) => {
      const filteredState = filterOutItem(state, collectionId, id);

      return filteredState;
    },

    [approvalPromotion.COMPLETE]: (state, { id, collectionId, promotion }) => {
      const updatedState = updateItemInData(state, collectionId, id, promotion);

      return updatedState;
    },
  },
  initialState
);
