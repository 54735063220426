import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import {
  createReactRouterV6Options,
  getWebInstrumentations,
  initializeFaro,
  ReactIntegration,
} from '@grafana/faro-react';

import { TracingInstrumentation } from '@grafana/faro-web-tracing';

const GRAFANA_FARO_COLLECTION_URL = '/api/3/rum';

function loadSetup(token: string) {
  initializeFaro({
    url: GRAFANA_FARO_COLLECTION_URL,
    apiKey: token,

    app: {
      name: 'pocket',
    },
    ignoreUrls: [window.location.origin + GRAFANA_FARO_COLLECTION_URL],
    instrumentations: [
      ...getWebInstrumentations({
        captureConsole: true, // Enable console capture
        enablePerformanceInstrumentation: true, // Enable performance tracking
      }),
      new TracingInstrumentation(),
      new ReactIntegration({
        router: createReactRouterV6Options({
          createRoutesFromChildren,
          matchRoutes,
          Routes,
          useLocation,
          useNavigationType,
        }),
      }),
    ],
  });
}

export default loadSetup;
