import { useMemo } from 'react';

import { Settings } from 'sigma/settings';

import { createNodeImageProgram } from '@sigma/node-image';
import { drawHover, drawLabel } from 'util/graph/canvas-utils';

function useDataContainerSettings() {
  const settings: Partial<Settings> = useMemo(
    () => ({
      nodeProgramClasses: {
        image: createNodeImageProgram({
          size: { mode: 'force', value: 256 },
        }),
      },
      defaultDrawNodeLabel: drawLabel,
      defaultDrawNodeHover: drawHover,
      allowInvalidContainer: true,
      defaultNodeType: 'image',
      defaultEdgeType: 'arrow',
      labelDensity: 0.07,
      labelGridCellSize: 60,
      labelRenderedSizeThreshold: 15,
      labelFont: 'Lato, sans-serif',
      zIndex: true,
    }),
    []
  );

  return settings;
}

export default useDataContainerSettings;
