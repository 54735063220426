import { fetchStatistics } from 'actions';
import withRouter from 'app/withRouter';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import '@react-sigma/core/lib/react-sigma.min.css';

import Screen from 'components/Screen/Screen';

import DataLoader from './Components/DataLoader';

import './KnowledgeGraph.scss';

export class KnowledgeGraph extends Component {
  render() {
    return (
      <>
        <Screen className="KnowledgeGraph">
          <DataLoader />
        </Screen>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

export default compose(
  withRouter,
  connect(mapStateToProps, { fetchStatistics }),
  injectIntl
)(KnowledgeGraph);
