import { FC, useEffect } from 'react';

import { Loading, SomethingIsWrong, HappyPath } from './';

import PageState from '../utils/PageState';

import Flags from 'providers/FeatureFlagProvider/types';

import { useFeatureFlags } from 'providers/FeatureFlagProvider';

interface Props {
  pageState: PageState;
  pinnedMessage: any;
  dismissMessage: any;
  featureFlags: Flags;
}

const Page: FC<Props> = ({
  pageState,
  pinnedMessage,
  dismissMessage,
  featureFlags,
}) => {
  let render = null;

  const { updateFeatureFlags } = useFeatureFlags();

  useEffect(() => {
    if (featureFlags) {
      updateFeatureFlags(featureFlags);
    }
  }, [featureFlags, updateFeatureFlags]);

  switch (pageState) {
    case PageState.Success:
      render = (
        <HappyPath
          pinnedMessage={pinnedMessage}
          dismissMessage={dismissMessage}
        />
      );
      break;
    case PageState.Loading:
      render = <Loading />;
      break;
    case PageState.SomethingIsWrong:
      render = <SomethingIsWrong />;
      break;
  }

  return <>{render}</>;
};
export default Page;
