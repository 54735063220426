import { createContext, useState, useContext, ReactNode } from 'react';

import Flags from './types';

const defaultFeatureFlags: Flags = {
  bookmarks: false,
  dataset_graph: false,
  dataset_promotions: false,
  grafana_faro: false,
  knowledge_graph: false,
  remote_datasets: false,
  xref: false,
};

export const FeatureFlagContext = createContext<{
  featureFlags: Flags;
  updateFeatureFlags: (featureFlags: Flags) => void;
}>({
  featureFlags: defaultFeatureFlags,
  updateFeatureFlags: () => {},
});

type Props = {
  children: ReactNode;
};

export const FeatureFlagProvider: React.FC<Props> = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState<Flags>(defaultFeatureFlags);

  const updateFeatureFlags = async (apiFeatureFlags: Flags) => {
    setFeatureFlags(apiFeatureFlags);
  };

  return (
    <FeatureFlagContext.Provider value={{ featureFlags, updateFeatureFlags }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlags = () => {
  return useContext(FeatureFlagContext);
};
