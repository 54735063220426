import { FC } from 'react';

import {
  type Promotion,
  DrawerSharedProps,
} from 'components/Promotions/PromotionManager/types';
import { Dialog, Classes, Button, Intent } from '@blueprintjs/core';

import useDeletePromotion from './hooks/useDeletePromotion';

import { showWarningToast } from 'app/toast';

export interface Props {
  promotion: Promotion;
  drawer: DrawerSharedProps;
  collectionId: number;
}

const DeletePromotion: FC<Props> = ({ promotion, drawer, collectionId }) => {
  const {
    isDialogOpen,
    setIsDialogOpen,
    confirmationText,
    setConfirmationText,
    handleConfirmDelete,
    handleDeleteClick,
    error,
    popError,
  } = useDeletePromotion({
    promotion,
    drawer,
    collectionId,
  });

  if (popError) {
    showWarningToast(popError);
  }

  return (
    <>
      <div className="danger-zone mt-4">
        <Button intent={Intent.DANGER} onClick={handleDeleteClick}>
          Delete Promotion
        </Button>
      </div>
      <Dialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        title="Confirm Deletion"
        icon="trash"
      >
        <div className={Classes.DIALOG_BODY}>
          <p>
            Are you sure you want to permanently delete{' '}
            <strong>{promotion.title}</strong> and all contained items? This
            cannot be undone.
          </p>
          <p>
            Please enter <strong>{promotion.title}</strong> to confirm:
          </p>
          <div className="bp4-input-group">
            <input
              type="text"
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}
              className="bp4-input"
              placeholder={promotion.title}
            />
          </div>
          {error && <div className="text-red-500 mt-2">{error}</div>}
        </div>
        <div className={`${Classes.DIALOG_FOOTER} flex space-x-2`}>
          <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
          <Button
            intent={Intent.DANGER}
            onClick={handleConfirmDelete}
            disabled={
              confirmationText.toLowerCase().trim() !==
              promotion.title.toLowerCase().trim()
            }
          >
            Confirm Delete
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default DeletePromotion;
