import { FC } from 'react';

import { Promotion } from 'components/Promotions/PromotionManager/types';

import { FaUser, FaCalendarAlt } from 'react-icons/fa';

import DetailSection from 'components/Drawer/DetailSection';

import StatusChangedBy from './Components/StatusChangedBy';
import StatusInfo from './Components/StatusInfo';

export interface Props {
  promotion: Promotion;
}

const PromotionTab: FC<Props> = ({ promotion }) => {
  const formattedDate = new Date(promotion.created_at)
    .toISOString()
    .split('T')[0];

  return (
    <div className="drawer-full-screen-section">
      <DetailSection label="Title" value={promotion.title} />

      <StatusInfo status={promotion.status} />

      <StatusChangedBy promotion={promotion} />

      <DetailSection
        label="Promoted by"
        value={promotion.promoted_by.label}
        icon={<FaUser />}
      />
      <DetailSection
        label="Created"
        value={formattedDate}
        icon={<FaCalendarAlt />}
      />
      {promotion.description && (
        <DetailSection label="Description" value={promotion.description} />
      )}
    </div>
  );
};

export default PromotionTab;
