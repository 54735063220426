import { SigmaContainer, useSigma } from '@react-sigma/core';

import { FC, useEffect } from 'react';
import { MultiDirectedGraph } from 'graphology';

import useDataContainerSettings from 'hooks/graph/useDataContainerSettings';

const GraphDataController: FC = ({ children }) => {
  const sigma = useSigma();
  const graph = sigma.getGraph();

  useEffect(() => {
    if (!graph) return;

    graph.addNode('A', { x: 0, y: 0, label: 'Node A', size: 10 });
    graph.addNode('B', { x: 1, y: 1, label: 'Node B', size: 10 });
    graph.addNode('C', { x: 0, y: 1, label: 'Node C', size: 10 });
    graph.addNode('D', { x: 1.5, y: 1, label: 'Node D', size: 10 });
    graph.addNode('E', { x: -1.5, y: 1, label: 'Node E', size: 10 });
    graph.addNode('Z', { x: -2.5, y: 2, label: 'Node Z', size: 10 });
    graph.addNode('R', { x: 2, y: 0, label: 'Richard', size: 10 });

    graph.addEdgeWithKey('rel1', 'A', 'B', { label: 'REL_1' });
    graph.addEdgeWithKey('rel2', 'A', 'B', { label: 'REL_2' });
    graph.addEdgeWithKey('rel3', 'A', 'C', { label: 'REL_3' });
    graph.addEdgeWithKey('rel4', 'C', 'B', { label: 'REL_4' });
    graph.addEdgeWithKey('rel5', 'E', 'C', { label: 'REL_5' });

    graph.addEdgeWithKey('rel6', 'R', 'Z', { label: 'REL_6' });

    return () => graph.clear();
  }, [graph]);

  return <>{children}</>;
};

const TabGraph = () => {
  const settings = useDataContainerSettings();

  return (
    <div className="graph-drawer-tab-details">
      <SigmaContainer graph={MultiDirectedGraph} settings={settings}>
        <GraphDataController />
      </SigmaContainer>
    </div>
  );
};

export default TabGraph;
