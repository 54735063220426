import { FC, useState } from 'react';

import { Tab, Tabs, TabId, Drawer, MaybeElement } from '@blueprintjs/core';
import { BlueprintIcons_16Id } from '@blueprintjs/icons/lib/esm/generated-icons/16px/blueprint-icons-16';

import company from './db/company';

import GraphDrawerHeader from './GraphDrawerHeader';
import CompanyDetails from './CompanyDetails';
import TabGraph from './TabGraph';

import './styles/GraphDrawer.scss';

type Props = {
  isOpen: boolean;
  title: string;
  icon: BlueprintIcons_16Id | MaybeElement;
  setVisiblity: React.Dispatch<React.SetStateAction<boolean>>;
};

const GraphDrawer: FC<Props> = ({ isOpen, title, icon, setVisiblity }) => {
  const closeListener = () => {
    setVisiblity(false);
  };

  /**
   * Issue: https://github.com/occrp/alfred/issues/54
   * activeTabId is something as a safe bay;
   * Graph rendering should be started ONLY if active tab requires graph rendering
   */
  const [activeTabId, setActiveTabId] = useState<TabId | null>(null);
  const tabOnChange = (newTabId: TabId) => {
    setActiveTabId(newTabId);
  };

  return (
    <>
      <Drawer
        isOpen={isOpen}
        canOutsideClickClose={true}
        icon={icon}
        title={title}
        onClose={closeListener}
      >
        <section className="graph-drawer">
          <GraphDrawerHeader company={company} />
          <Tabs
            id="graph-drawer-id"
            className="graph-drawer-tab-container"
            defaultSelectedTabId="graph-drawer-tab-info-id"
            onChange={tabOnChange}
          >
            <Tab
              id="graph-drawer-tab-info-id"
              title="Info"
              icon="inbox"
              panel={
                <>
                  <CompanyDetails company={company} />
                </>
              }
            />
            <Tab
              id="graph-drawer-tab-details-id"
              title="Details"
              icon="gantt-chart"
              panel={
                <>
                  {activeTabId === 'graph-drawer-tab-details-id' && (
                    <TabGraph />
                  )}
                </>
              }
            />
          </Tabs>
        </section>
      </Drawer>
    </>
  );
};

export default GraphDrawer;
