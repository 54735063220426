import { LinkButton } from 'components/common';
import { FormattedMessage } from 'react-intl';

import { testingIdentifiers } from '../db';

import { useFeatureFlags } from 'providers/FeatureFlagProvider';

const GraphItem = () => {
  const { featureFlags } = useFeatureFlags();

  if (!featureFlags.knowledge_graph) return null;

  return (
    <LinkButton
      to="/knowledge-graph"
      icon="flow-review-branch"
      minimal={true}
      className="Navbar_collections-button"
      data-testid={testingIdentifiers.tabGraph}
    >
      <FormattedMessage id="nav.collections" defaultMessage="Graph" />
    </LinkButton>
  );
};

export default GraphItem;
