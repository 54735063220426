import { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withRouter from 'app/withRouter';
import { collectionXrefFacetsQuery } from 'queries';
import {
  fetchCollection,
  queryCollectionXref,
  forceMutate,
  fetchCollectionV3,
} from 'actions';
import {
  selectCollection,
  selectCollectionXrefResult,
  selectFeatureFlags,
  selectSession,
} from 'selectors';
import timestamp from 'util/timestamp';

class CollectionContextLoader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { timeout: null };
    this.fetchRefresh = this.fetchRefresh.bind(this);
  }

  componentDidMount() {
    this.fetchRefresh();
    this.fetchIfNeeded();
  }

  componentDidUpdate() {
    this.fetchIfNeeded();
  }

  componentWillUnmount() {
    clearTimeout(this.state.timeout);
  }

  determineFetchRequestEndPont(collectionId, refresh) {
    const { dataset_promotions, remote_datasets } = this.props.features;
    const isFetchV3Request = dataset_promotions || remote_datasets;

    if (isFetchV3Request) {
      const token = this.props.session.token;

      this.props.fetchCollectionV3({
        id: collectionId,
        refresh,
        sessionToken: token,
      });
    } else {
      this.props.fetchCollection({ id: collectionId, refresh });
    }
  }

  fetchIfNeeded() {
    const { collectionId, collection } = this.props;

    if (collection.shouldLoadDeep) {
      const refresh = collection.shallow === false;

      this.determineFetchRequestEndPont(collectionId, refresh);
    }

    const { xrefResult, xrefQuery } = this.props;
    if (xrefResult.shouldLoad) {
      this.props.queryCollectionXref({ query: xrefQuery, result: xrefResult });
    }
  }

  fetchRefresh() {
    if (this.props.features.no_reload) return;

    const { collection, collectionId } = this.props;
    const { status } = collection;
    clearTimeout(this.state.timeout);
    const staleDuration = status.active ? 3000 : 30000;
    const age = timestamp() - collection.loadedAt;
    const shouldRefresh = age > staleDuration && !collection.isPending;
    if (shouldRefresh) {
      // this.props.forceMutate();
      const refresh = collection.shallow === false;
      this.determineFetchRequestEndPont(collectionId, refresh);
    }
    const timeout = setTimeout(this.fetchRefresh, 1000);
    this.setState({ timeout });
  }

  render() {
    return this.props.children;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location, collectionId } = ownProps;
  const xrefQuery = collectionXrefFacetsQuery(location, collectionId);
  return {
    collection: selectCollection(state, collectionId),
    features: selectFeatureFlags(state),
    session: selectSession(state),
    xrefQuery,
    xrefResult: selectCollectionXrefResult(state, xrefQuery),
  };
};

const mapDispatchToProps = {
  forceMutate,
  fetchCollection,
  queryCollectionXref,
  fetchCollectionV3,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CollectionContextLoader);
