import { FC, useState } from 'react';

import { Drawer, MaybeElement, Tabs, Tab } from '@blueprintjs/core';
import { BlueprintIcons_16Id } from '@blueprintjs/icons/lib/esm/generated-icons/16px/blueprint-icons-16';
import { Icon } from '@blueprintjs/core';

import { Status, type Promotion, DrawerSharedProps } from '../../types';

import { PromotionTab, GraphTab, ApprovalTab } from './Tabs';
import DeletePromotion from './Components/DeletePromotion';
export interface Props {
  promotion: Promotion;
  isOpen: boolean;
  title: string;
  icon: BlueprintIcons_16Id | MaybeElement;
  setVisiblity: React.Dispatch<React.SetStateAction<boolean>>;
  collectionId: number;
}

const PromotionManagerDrawer: FC<Props> = ({
  promotion,
  isOpen,
  title,
  icon,
  setVisiblity,
  collectionId,
}) => {
  const [uiPromotion, setUiPromotion] = useState<Promotion>(promotion);

  const closeListener = () => {
    setVisiblity(false);
  };

  const approvalTabIsVisible = promotion.status === Status.New;
  const deleteButtonIsVisible = uiPromotion.status === Status.New;

  const sharedProps: DrawerSharedProps = {
    setVisiblity,
  };

  return (
    <>
      <Drawer
        isOpen={isOpen}
        canOutsideClickClose={true}
        icon={icon}
        title={title}
        onClose={closeListener}
        className="drawer-full-screen"
      >
        <div className="py-4 px-6 drawer-full-screen-container">
          <section className="graph-drawer-header">
            <div className="draph-drawer-header-entity-info">
              <Icon
                icon="build"
                className="draph-drawer-header-entity-info-icon"
              />
              <span>Promotion</span>
            </div>
            <div className="graph-drawer-header-title">{promotion.title}</div>

            {deleteButtonIsVisible && (
              <DeletePromotion
                promotion={promotion}
                drawer={sharedProps}
                collectionId={collectionId}
              />
            )}
          </section>
          <Tabs
            id="graph-drawer-id"
            className="promotion-manager-drawer-tab-container p-2 drawer-full-screen-tabs"
            defaultSelectedTabId="promotion-manager-tab-promotion-id"
          >
            <Tab
              id="promotion-manager-tab-promotion-id"
              title="Promotion"
              icon="inbox"
              className="drawer-full-screen-tab"
              panel={<PromotionTab promotion={uiPromotion} />}
            />
            {uiPromotion.diagram_id && (
              <Tab
                id="promotion-manager-tab-graph-details-id"
                title="Details"
                icon="gantt-chart"
                className="drawer-full-screen-tab"
                panel={<GraphTab diagramId={uiPromotion.diagram_id} />}
              />
            )}
            {approvalTabIsVisible && (
              <Tab
                id="promotion-manager-tab-promotion-approvals-id"
                title="Approvals"
                icon="settings"
                className="drawer-full-screen-tab"
                panel={
                  <ApprovalTab
                    promotion={uiPromotion}
                    setUiPromotion={setUiPromotion}
                    collectionId={collectionId}
                  />
                }
              />
            )}
          </Tabs>
        </div>
      </Drawer>
    </>
  );
};

export default PromotionManagerDrawer;
