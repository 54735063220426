import React from 'react';

interface DetailSectionProps {
  label: string;
  value: string | number;
  widthClass?: string;
  labelColor?: string;
  icon?: React.ReactNode;
}

const DetailSection: React.FC<DetailSectionProps> = ({
  label,
  value,
  widthClass = 'w-1/3',
  labelColor = '#5f6b7c',
  icon,
}) => (
  <section className="mt-4 flex">
    <span
      className={`font-bold mr-1 ${widthClass} block`}
      style={{ color: labelColor }}
    >
      {label}:
    </span>
    <span className="flex items-center w-2/3">
      {icon && <span className="mr-2">{icon}</span>}
      {value}
    </span>
  </section>
);

export default DetailSection;
