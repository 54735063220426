import {
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import AnimateHeight from 'react-animate-height';
import { IoMdArrowDropright, IoMdArrowDropdown } from 'react-icons/io';

const DURATION = 300;

const Panel: FC<
  PropsWithChildren<{ title: ReactNode | string; initiallyDeployed?: boolean }>
> = ({ title, initiallyDeployed, children }) => {
  const [isDeployed, setIsDeployed] = useState(initiallyDeployed || false);
  const dom = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isDeployed)
      setTimeout(() => {
        if (dom.current)
          dom.current.parentElement?.scrollTo({
            top: dom.current.offsetTop - 5,
            behavior: 'smooth',
          });
      }, DURATION);
  }, [isDeployed]);

  return (
    <div className="panel" ref={dom}>
      <h2
        className="panel-title-container"
        onClick={() => setIsDeployed((v) => !v)}
      >
        <button type="button" className="panel-button">
          {isDeployed ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
        </button>
        <span className="panel-title">{title}</span>{' '}
      </h2>
      <AnimateHeight duration={DURATION} height={isDeployed ? 'auto' : 0}>
        <section className="panel-animate-height">{children}</section>
      </AnimateHeight>
    </div>
  );
};

export default Panel;
