import { useSigma } from '@react-sigma/core';
import { FC, useEffect, useState } from 'react';

import FiltersState from 'types/graph/FiltersState';

import { testingIdentifiers } from './db';

function prettyPercentage(val: number): string {
  return (val * 100).toFixed(1) + '%';
}

type Props = {
  filters: FiltersState;
  testingIds?: {
    container?: string;
    title?: string;
    subTitle?: string;
  };
};

type TestingIds = Pick<Props, 'testingIds'>;

function fetchTestingIds({ testingIds }: TestingIds) {
  const containerId = testingIds?.container
    ? testingIds.container
    : testingIdentifiers.container;

  const titleId = testingIds?.title
    ? testingIds.title
    : testingIdentifiers.title;

  const subtitleId = testingIds?.subTitle
    ? testingIds.subTitle
    : testingIdentifiers.subTitle;

  return { containerId, titleId, subtitleId };
}

const GraphTitle: FC<Props> = ({ filters, testingIds }) => {
  const sigma = useSigma();
  const graph = sigma.getGraph();

  const { containerId, titleId, subtitleId } = fetchTestingIds({
    testingIds,
  });

  const [visibleItems, setVisibleItems] = useState<{
    nodes: number;
    edges: number;
  }>({ nodes: 0, edges: 0 });
  useEffect(() => {
    // To ensure the graphology instance has up to data "hidden" values for
    // nodes, we wait for next frame before reindexing. This won't matter in the
    // UX, because of the visible nodes bar width transition.
    requestAnimationFrame(() => {
      const index = { nodes: 0, edges: 0 };
      graph.forEachNode((_, { hidden }) => !hidden && index.nodes++);
      graph.forEachEdge(
        (_, _2, _3, _4, source, target) =>
          !source.hidden && !target.hidden && index.edges++
      );
      setVisibleItems(index);
    });
  // eslint-disable-next-line
  }, [filters]);

  return (
    <div className="graph-title-container" id={containerId}>
      <span className="graph-title" id={titleId}>
        A cartography of Wikipedia pages around data visualization
      </span>
      <span className="graph-title-subtitle" id={subtitleId}>
        <i>
          {graph.order} node{graph.order > 1 ? 's' : ''}{' '}
          {visibleItems.nodes !== graph.order
            ? ` (only ${prettyPercentage(
                visibleItems.nodes / graph.order
              )} visible)`
            : ''}
          , {graph.size} edge
          {graph.size > 1 ? 's' : ''}{' '}
          {visibleItems.edges !== graph.size
            ? ` (only ${prettyPercentage(
                visibleItems.edges / graph.size
              )} visible)`
            : ''}
        </i>
      </span>
    </div>
  );
};

export default GraphTitle;
