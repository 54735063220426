import { FC } from 'react';

import { Icon } from '@blueprintjs/core';

import Company from 'types/db/Company';

import './styles/GraphDrawerHeader.scss';

type Props = {
  company: Company;
};

const GraphDrawerHeader: FC<Props> = ({ company }) => {
  return (
    <>
      <section className="graph-drawer-header">
        <div className="draph-drawer-header-entity-info">
          <Icon
            icon="office"
            className="draph-drawer-header-entity-info-icon"
          />
          <span>Company</span>
        </div>
        <div className="graph-drawer-header-title">
          {company.name.toUpperCase()}
        </div>
        <div>Last viewed 3 seconds ago</div>
      </section>
    </>
  );
};

export default GraphDrawerHeader;
