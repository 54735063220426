import { FC } from 'react';

import TableHeader from 'components/Table/TableHeader';

import useFetchCollectionHistory from './hooks/useFetchCollectionHistory';

import headerColumns from './db/header-columns';
import rowConfigurations from './db/row-configurations';
import columnIdentifiers, { ColumnIdentifier } from './db/column-identfier';

import { createTableColumn } from './factories/createTableColumn';
import { HistoryLog } from './types';
import { FaSync } from 'react-icons/fa';

type Props = {
  collectionId: number;
};

const HistoryTab: FC<Props> = ({ collectionId }) => {
  const { historyLogs, apiRequest } = useFetchCollectionHistory({
    collectionId,
  });

  const handleHistoryLogSelect = (_: HistoryLog) => {};

  return (
    <>
      <div className="mb-2 flex w-full justify-end">
        <button
          onClick={apiRequest}
          className="flex items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          <FaSync className="mr-2" /> Refresh
        </button>
      </div>

      <TableHeader columns={headerColumns} />
      {historyLogs.map((record, rowIndex) => (
        <section key={rowIndex} className="relative flex flex-row">
          {Object.keys(columnIdentifiers).map((key, colIndex) => {
            const identifierKey = key as ColumnIdentifier;
            const identifier = key as keyof typeof rowConfigurations;
            const params = rowConfigurations[identifier];
            const column = createTableColumn({
              identifier: identifierKey,
              params: params,
              onHistoryLogSelect: handleHistoryLogSelect,
            });

            return (
              <section
                key={colIndex}
                style={{ width: params.width }}
                className="p-3 px-2 border border-gray-200"
              >
                {column.render(record)}
              </section>
            );
          })}
        </section>
      ))}
    </>
  );
};

export default HistoryTab;
