import { FC } from 'react';

import { Status } from 'components/Promotions/PromotionManager/types';
import DetailSection from 'components/Drawer/DetailSection';
import { FaCheck, FaTimes } from 'react-icons/fa';

export interface Props {
  status: Status;
}

const StatusInfo: FC<Props> = ({ status }) => {
  let icon: React.ReactNode | undefined = undefined;

  switch (status) {
    case Status.Approved:
      icon = <FaCheck color="green" />;
      break;
    case Status.Declined:
      icon = <FaTimes color="red" />;
      break;
  }

  return <DetailSection label="Status" value={status} icon={icon} />;
};

export default StatusInfo;
