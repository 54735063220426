import { FC } from 'react';

type GraphDrawerLabelProps = {
  text: string;
};

const GraphDrawerLabel: FC<GraphDrawerLabelProps> = ({ text }) => {
  return <span className="graph-drawer-label">{text}:</span>;
};

export default GraphDrawerLabel;
