import { FC, useEffect } from 'react';

import { useRegisterEvents, useSigma } from '@react-sigma/core';

function getMouseLayer() {
  return document.querySelector('.sigma-mouse');
}

type Props = {
  setHoveredNode: (node: string | null) => void;
  hoveredNode: string | null;
  setSelectedNodeIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const GraphEventsController: FC<Props> = ({
  setHoveredNode,
  hoveredNode,
  setSelectedNodeIsVisible,
  children,
}) => {
  const sigma = useSigma();
  const graph = sigma.getGraph();
  const registerEvents = useRegisterEvents();

  /**
   * Initialize here settings that require to know the graph and/or the sigma
   * instance:
   */
  useEffect(() => {
    registerEvents({
      clickNode({ node }) {
        const mouseLayer = getMouseLayer();
        if (hoveredNode !== node) {
          setSelectedNodeIsVisible(true);
          setHoveredNode(node);
          if (mouseLayer) mouseLayer.classList.add('mouse-pointer');
        } else {
          setHoveredNode(null);
          if (mouseLayer) mouseLayer.classList.remove('mouse-pointer');
        }
      },
    });
  }, [
    graph,
    registerEvents,
    setHoveredNode,
    hoveredNode,
    setSelectedNodeIsVisible,
  ]);

  return <>{children}</>;
};

export default GraphEventsController;
