import { FC } from 'react';

import { IoMdClose } from 'react-icons/io';
import { Button } from '@blueprintjs/core';

import { Dataset, NodeData, Cluster } from 'types/graph';

import './styles/GraphSelectedNode.css';

import GraphDrawer from 'components/Graph/Drawer/GraphDrawer';

export type DrawerOptions = {
  isOpen: boolean;
  setVisiblity: React.Dispatch<React.SetStateAction<boolean>>;
};

type Props = {
  selectedNodeIsVisible: boolean;
  setSelectedNodeIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  hoveredNode: string | null;
  dataset: Dataset;

  // Drawer Options
  drawerOptions?: DrawerOptions;
};

const GraphSelectedNode: FC<Props> = ({
  hoveredNode,
  dataset,
  selectedNodeIsVisible,
  setSelectedNodeIsVisible,
  drawerOptions,
}) => {
  if (!selectedNodeIsVisible) return null;

  if (!hoveredNode) return null;

  const selectedNode = dataset.nodes.find((node: NodeData) => {
    return node.key === hoveredNode;
  });

  if (!selectedNode) return null;

  const selectedCluster = dataset.clusters.find((cluster: Cluster) => {
    return cluster.key === selectedNode.cluster;
  }) as Cluster;

  const closeListener = () => {
    setSelectedNodeIsVisible(false);
  };

  const detailsListener = () => {
    drawerOptions?.setVisiblity(true);
  };

  return (
    <div className="graph-selected-node">
      <div className="graph-selected-node-container">
        <div className="graph-selected-node-close">
          <IoMdClose onClick={closeListener} />
        </div>

        <span>{selectedNode.tag}</span>
        <span>{selectedNode.label}</span>
        <span style={{ color: selectedCluster.color }}>
          {selectedCluster.clusterLabel}
        </span>
        {drawerOptions && (
          <>
            <Button
              onClick={detailsListener}
              className="graph-selected-node-details"
            >
              Details ...
            </Button>
            <GraphDrawer
              title={selectedNode.label}
              isOpen={drawerOptions.isOpen}
              setVisiblity={drawerOptions.setVisiblity}
              icon="person"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default GraphSelectedNode;
