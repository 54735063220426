import { FC } from 'react';

import {
  Promotion,
  Status,
} from 'components/Promotions/PromotionManager/types';

import useApprovalPromotion from 'components/Promotions/PromotionManager/hooks/useApprovalPromotion';

import './styles/ApprovalTab.css';
import { Button, Dialog, Divider, Intent } from '@blueprintjs/core';

import { showWarningToast } from 'app/toast';

type ApprovalControlsProp = {
  onApprove: () => void;
  onReject: () => void;
};

const ApprovalControls: FC<ApprovalControlsProp> = ({
  onApprove,
  onReject,
}) => {
  return (
    <div className="promotion-approval-tab">
      <div>
        <span className="italic text-lg">
          As an admin for this dataset you are able to approve or reject this
          promotion
        </span>
      </div>
      <div className="my-2">
        <Divider />
      </div>
      <div>
        <button
          className="bp4-button bp4-intent-danger promotion-approval-tab-button"
          onClick={onReject}
        >
          Reject
        </button>
        <span className="ml-2">
          Pressing this button will <span className="font-bold">reject</span>{' '}
          this Promotion Request
        </span>
      </div>

      <div className="my-4">
        <Divider />
      </div>

      <div>
        <button
          className="bp4-button bp4-intent-success promotion-approval-tab-button"
          onClick={onApprove}
        >
          Approve
        </button>
        <span className="ml-2">
          Pressing this button will <span className="font-bold">approval</span>{' '}
          this Promotion Request
        </span>
      </div>
    </div>
  );
};

type Props = {
  promotion: Promotion;
  setUiPromotion: React.Dispatch<React.SetStateAction<Promotion>>;
  collectionId: number;
};

const ApprovalTab: FC<Props> = ({
  promotion,
  setUiPromotion,
  collectionId,
}) => {
  const {
    isDialogOpen,
    executeRequest,
    setIsDialogOpen,
    responsePromotion,
    setActionType,
    actionType,
    error,
    loading,
  } = useApprovalPromotion(promotion, setUiPromotion, collectionId);

  const handleApproveClick = () => {
    setActionType(Status.Approved);
    setIsDialogOpen(true);
  };

  const handleRejectClick = () => {
    setActionType(Status.Declined);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setActionType(Status.New);
  };

  const handleConfirm = () => {
    executeRequest();
  };

  if (error) {
    showWarningToast(
      'There was a problem with this request. Please try again later.'
    );
  }

  const actionLabel = (actionType: Status) => {
    return actionType === Status.Approved ? 'approve' : 'decline';
  };

  return (
    <>
      {!loading && !responsePromotion && (
        <ApprovalControls
          onApprove={handleApproveClick}
          onReject={handleRejectClick}
        />
      )}

      {loading && <>Loading ...</>}

      {responsePromotion && (
        <span>
          Promotion: {responsePromotion.title} has a new status:{' '}
          {responsePromotion.status}
        </span>
      )}

      <Dialog
        isOpen={isDialogOpen}
        onClose={handleDialogClose}
        title="Confirm Changes"
        icon="confirm"
      >
        <div className="p-4">
          <div className="">
            Are you sure you want to {actionLabel(actionType)}{' '}
            <strong>{promotion.title}</strong>?
          </div>
          <div className="flex mt-1 justify-between">
            <Button text="No" onClick={handleDialogClose} />
            <Button
              text="Yes"
              intent={Intent.PRIMARY}
              onClick={handleConfirm}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ApprovalTab;
