import { FC } from 'react';

import { Divider } from '@blueprintjs/core';

import GraphDrawerContainer from './GraphDrawerContainer';
import GraphDrawerLabel from './GraphDrawerLabel';

import Company from 'types/db/Company';

type Props = {
  company: Company;
};

const CompanyDetails: FC<Props> = ({ company }) => {
  return (
    <section>
      <GraphDrawerContainer>
        <GraphDrawerLabel text="Name" />
        <span>{company.name}</span>
      </GraphDrawerContainer>
      <GraphDrawerContainer>
        <GraphDrawerLabel text="Jurisdiction" />
        <span>{company.jurisdiction}</span>
      </GraphDrawerContainer>
      <GraphDrawerContainer>
        <GraphDrawerLabel text="Registration number" />
        <span>{company.registrationNumber}</span>
      </GraphDrawerContainer>
      <GraphDrawerContainer>
        <GraphDrawerLabel text="Incorporation date" />
        <span>{company.incorporationDate}</span>
      </GraphDrawerContainer>
      <GraphDrawerContainer>
        <GraphDrawerLabel text="Address" />
        <span>{company.address}</span>
      </GraphDrawerContainer>
      <GraphDrawerContainer>
        <GraphDrawerLabel text="Classification" />
        <span>{company.classification}</span>
      </GraphDrawerContainer>
      <GraphDrawerContainer>
        <GraphDrawerLabel text="Country" />
        <span>{company.country}</span>
      </GraphDrawerContainer>
      <GraphDrawerContainer>
        <GraphDrawerLabel text="Dissolution date" />
        <span>{company.dissolutionDate}</span>
      </GraphDrawerContainer>
      <GraphDrawerContainer>
        <GraphDrawerLabel text="Legal form" />
        <span>{company.legalForm}</span>
      </GraphDrawerContainer>
      <GraphDrawerContainer>
        <GraphDrawerLabel text="Source link" />
        <span>{company.sourceLink}</span>
      </GraphDrawerContainer>
      <GraphDrawerContainer>
        <GraphDrawerLabel text="Status" />
        <span>{company.status}</span>
      </GraphDrawerContainer>
      <Divider />
      <GraphDrawerContainer>
        <GraphDrawerLabel text="Dataset" />
        <span>{company.dataset}</span>
      </GraphDrawerContainer>
    </section>
  );
};

export default CompanyDetails;
