'use client';

import { FC } from 'react';

import { useSelector } from 'react-redux';

import { Routes, Route } from 'react-router-dom';
import { FaroRoutes } from '@grafana/faro-react';

import { routes as legacyRedirects } from 'app/legacyRedirects';

import NotFoundScreen from 'screens/NotFoundScreen/NotFoundScreen';

import { useFeatureFlags } from 'providers/FeatureFlagProvider';

import { AppState } from './types';

import routes from './routes';

import initFaro from '../config/grafana';

const AppFaroRoutes = () => {
  const session = useSelector((state: AppState) => state.session);
  const token = session.token;

  initFaro(token);

  return (
    <FaroRoutes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
      {legacyRedirects}
      <Route path="*" element={<NotFoundScreen />} />
    </FaroRoutes>
  );
};

const DefaultRoutes = () => {
  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
      {legacyRedirects}
      <Route path="*" element={<NotFoundScreen />} />
    </Routes>
  );
};

const AppRoutes: FC = () => {
  const session = useSelector((state: AppState) => state.session);
  const isLoggedIn = session.loggedIn;

  const { featureFlags } = useFeatureFlags();

  if (featureFlags.grafana_faro && isLoggedIn) return <AppFaroRoutes />;

  return <DefaultRoutes />;
};

export default AppRoutes;
