import Company from 'types/db/Company';

const company: Company = {
  name: 'DARCH ASSOCIATES LIMITED',
  jurisdiction: 'United Kingdom',
  registrationNumber: '111',
  incorporationDate: '2018-01-02',
  address: '2 FOUR ASHES ROAD, DORRIDGE, SOLIHULL, B93 8NB',
  classification: '41100 - Development of building projects',
  country: 'United Kingdom',
  dissolutionDate: '2020-02-11',
  legalForm: 'Private Limited Company',
  sourceLink: 'data.companieshouse.gov.uk',
  status: 'dissolved',
  dataset: 'UK Companies House',
};

export default company;
