import { FC } from 'react';

import { FullScreenControl, ZoomControl } from '@react-sigma/core';

import { BiRadioCircleMarked } from 'react-icons/bi';
import {
  BsArrowsFullscreen,
  BsFullscreenExit,
  BsZoomIn,
  BsZoomOut,
} from 'react-icons/bs';

const GraphControllers: FC = () => {
  return (
    <div className="graph-controllers">
      <FullScreenControl>
        <BsArrowsFullscreen />
        <BsFullscreenExit />
      </FullScreenControl>
      <ZoomControl>
        <BsZoomIn />
        <BsZoomOut />
        <BiRadioCircleMarked />
      </ZoomControl>
    </div>
  );
};

export default GraphControllers;
