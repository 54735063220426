import { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from 'react';

import Sigma from 'sigma';

import { BsSearch } from 'react-icons/bs';
import { Attributes } from 'graphology-types';

import FiltersState from 'types/graph/FiltersState';

type Props = {
  sigma: Sigma<Attributes, Attributes, Attributes>;
  filters: FiltersState;
};

const SearchField: FC<Props> = ({ sigma, filters }) => {
  const [search, setSearch] = useState<string>('');
  const [values, setValues] = useState<Array<{ id: string; label: string }>>(
    []
  );
  const [selected, setSelected] = useState<string | null>(null);

  const refreshValues = () => {
    const newValues: Array<{ id: string; label: string }> = [];
    const lcSearch = search.toLowerCase();
    if (!selected && search.length > 1) {
      sigma
        .getGraph()
        .forEachNode((key: string, attributes: Attributes): void => {
          if (
            !attributes.hidden &&
            attributes.label &&
            attributes.label.toLowerCase().indexOf(lcSearch) === 0
          )
            newValues.push({ id: key, label: attributes.label });
        });
    }
    setValues(newValues);
  };

  // Refresh values when search is updated:
  // eslint-disable-next-line
  useEffect(() => refreshValues(), [search]);

  // Refresh values when filters are updated (but wait a frame first):
  // eslint-disable-next-line
  useEffect(() => { requestAnimationFrame(refreshValues) }, [filters]);

  useEffect(() => {
    if (!selected) return;

    sigma.getGraph().setNodeAttribute(selected, 'highlighted', true);
    const nodeDisplayData = sigma.getNodeDisplayData(selected);

    if (nodeDisplayData)
      sigma.getCamera().animate(
        { ...nodeDisplayData, ratio: 0.05 },
        {
          duration: 600,
        }
      );

    return () => {
      sigma.getGraph().setNodeAttribute(selected, 'highlighted', false);
    };
  // eslint-disable-next-line
  }, [selected]);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchString = e.target.value;
    const valueItem = values.find((value) => value.label === searchString);
    if (valueItem) {
      setSearch(valueItem.label);
      setValues([]);
      setSelected(valueItem.id);
    } else {
      setSelected(null);
      setSearch(searchString);
    }
  };

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && values.length) {
      setSearch(values[0].label);
      setSelected(values[0].id);
    }
  };

  return (
    <div className="search-wrapper">
      <input
        type="search"
        placeholder="Search in nodes..."
        list="nodes"
        value={search}
        onChange={onInputChange}
        onKeyDown={onKeyPress}
      />
      <BsSearch className="graph-search-icon" />
      <datalist id="nodes">
        {values.map((value: { id: string; label: string }) => (
          <option key={value.id} value={value.label}>
            {value.label}
          </option>
        ))}
      </datalist>
    </div>
  );
};

export default SearchField;
