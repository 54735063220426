import { FC } from 'react';

import { Button } from '@blueprintjs/core';

import './index.scss';

type Props = {
  expandToggleButtonIsActive: boolean;
  setExpandToggleButtonIsActive: React.Dispatch<React.SetStateAction<boolean>>;
};

const ExpandSideToggle: FC<Props> = ({
  expandToggleButtonIsActive,
  setExpandToggleButtonIsActive,
}) => {
  const expandToggleButtonOnClickListener = () => {
    setExpandToggleButtonIsActive(!expandToggleButtonIsActive);
  };

  return (
    <div className="expand-side-toggle-container__expand-toggle">
      <Button
        onClick={expandToggleButtonOnClickListener}
        icon={expandToggleButtonIsActive ? 'chevron-right' : 'chevron-left'}
        outlined
        className="expand-side-toggle-container__expand-toggle__button"
      />
    </div>
  );
};

export default ExpandSideToggle;
